<template>
  <h1 class="font-bold text-black head-title leading-none"> 
    {{ title }}
  </h1>  
</template>

<script>
  export default {
    props: [ 'title' ]
  }
</script>

<style lang="scss" scoped>
</style>