<template>
  <div class="container mx-auto">
    <div class="flex flex-wrap">
      <div class="px-4 w-full">
        <BigTitle title="Settings" class="mb-11 pt-40"/>
        <API />
      </div>
    </div>
  </div>
</template>

<script>
  import API from '@/components/settings/API'
  import BigTitle from '@/components/BigTitle'

  export default {
    components: {
      API,
      BigTitle
    },
  }
</script>

<style lang="scss" scoped>

</style>